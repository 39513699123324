import React from "react";

export default function SelectComponent({
  selectDetails,
  height,
  background,
  border,
  borderColor,
  value,
  onChange,
  placeholder,
  fontSize,
  color,
  paddingLeft,
  fontFamily,
}) {
  return (
    <div className="form-group" style={{ width: "100%" }}>
      <select
        className={`form-control mobile-font-size`}
        // className="form-control select-form-mobile-font-size"
        id="exampleSelect"
        style={{
          fontSize: fontSize ? fontSize : "24px",
          height,
          background,
          border,
          borderColor,
          fontFamily,
          paddingLeft: paddingLeft ? paddingLeft : "51px",
          color,
        }}
        value={value}
        onChange={onChange}
      >
        <option value={""}>
          {placeholder ? placeholder : "Select Option"}
        </option>
        {selectDetails?.map((list) => (
          <option value={list?.name}>{list?.name}</option>
        ))}
      </select>
    </div>
  );
}
