import React from "react";
import "./TextArea.scss";
export default function TextArea({
  placeholder,
  border,
  borderColor,
  paddingLeft,
  background,
  paddingTop,
  fontSize,
  fontFamily,
  color,
}) {
  return (
    <textarea
      className={`form-control mobile-font-size`}
      rows="7"
      placeholder={placeholder}
      style={{
        border,
        borderColor,
        paddingLeft,
        paddingTop,
        fontSize: fontSize ? fontSize : "24px",
        fontFamily: fontFamily ? fontFamily : "PT_Serif_Bold",
        color,
        background,
      }}
    ></textarea>
  );
}
