import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
// import Slider1 from "../../images/slider1.svg";
// import Slider2 from "../../images/slider1.svg";
// import Slider3 from "../../images/slider1.svg";
import "./Carousel.scss";
import StyledButton from "../styledButton";
import CarouselFormCover from "../CarouselFormCover";
export default function CarouselComp() {
  return (
    <div>
      <div className="dasktop-carosel">
        <div className="desktop-carousel-field-cover">
          <CarouselFormCover />
        </div>
        <Carousel
          preventMovementUntilSwipeScrollTolerance
          autoPlay={"2000"}
          infiniteLoop
          swipeScrollTolerance={100}
        >
          <div className="carousel-cover carousel-1">
            {/* <img
              scr={require("../../images/slider2.jpg")}
              className="carousel-bg-1"
            /> */}
            <div className="slider-caption">
              <div className="slider-caption-title">
                Get quick funding <br className="bg-screen-break" /> that works
                for you.
              </div>
              <div className="slider-caption-sub-ttitle">
                Access up to N5 million unsecured loans in
                <br className="bg-screen-break" />
                minutes. No hidden charges
              </div>
            </div>
          </div>
          <div className="carousel-cover carousel-2">
            <div className="slider-caption">
              <div className="slider-caption-title">
                Get quick funding <br className="bg-screen-break" />
                that works for you.
              </div>
              <div className="slider-caption-sub-ttitle">
                Access up to N5 million unsecured loans in
                <br className="bg-screen-break" /> minutes. No hidden charges
              </div>
            </div>
          </div>
        </Carousel>
        <div className="slider-caption-mobile-and-tab">
          <div className="slider-caption-title">
            Get quick funding that works for you.
          </div>
          <div className="slider-caption-sub-ttitle">
            Access up to N5 million unsecured loans in minutes. No hidden
            charges
          </div>
        </div>
      </div>
    </div>
  );
}
