import React from "react";
import "./ImageWrapper.scss";
import ContentDiv from "../ContentDiv";
export default function ImageWrapper({ image, title, subTitle }) {
  return (
    <div className="loan-type-card">
      <img className="image__card" src={image} alt="" style={{ width: "100%", height: "100%", objectFit:"cover" }} />
      <div className="loan-type-caption-div">
        <ContentDiv
          text={title}
          fontSize={"24px"}
          fontFamily={"sen-bold"}
          color={"white"}
        />
        <ContentDiv
          text={subTitle}
          fontSize={"14px"}
          fontFamily={"sen-medium"}
          color={"white"}
          lineHeight={"20px"}
        />
      </div>
    </div>
  );
}
