import React from "react";
import VectorA from "../../images/VectorA.svg";
import VectorB from "../../images/VectorB.svg";
import VectorC from "../../images/VectorC.svg";
import VectorD from "../../images/VectorD.svg";
import "./styles.scss";
export default function OurValues() {
  return (
    <div id="values" className="about-us-wrapper">
      <div className="about-us-text-container">
        <div className="about-us-text">Our Values</div>
      </div>
      <div className="about-us-cards-wrapper">
        <div className="about-us-card-one">
          <img src={VectorA} style={{ width: "48px", height: "48px" }} />
          <div style={{ fontWeight: "700", fontSize: "18px" }}>
            Customer First
          </div>
          {/* <div style={{ fontWeight: "400", fontSize: "12px", }}>
            A wonderful serenity has taken possession of my entire soul, spring
            which I enjoy with.
          </div> */}
        </div>
        <div className="about-us-card-two">
          <img src={VectorB} style={{ width: "48px", height: "48px" }} />
          <div style={{ fontWeight: "700", fontSize: "18px" }}>Integrity</div>
          {/* <div style={{ fontWeight: "400", fontSize: "12px" }}>
            A wonderful serenity has taken possession of my entire soul, like
            these sweet.
          </div> */}
        </div>
        <div className="about-us-card-three">
          <img src={VectorC} style={{ width: "48px", height: "48px" }} />
          <div style={{ fontWeight: "700", fontSize: "18px" }}>Passion</div>
          {/* <div style={{ fontWeight: "400", fontSize: "12px" }}>
            Transforming distribution and marketing with key capabilities in
            customer insight
          </div> */}
        </div>
        <div className="about-us-card-four">
          <img src={VectorD} style={{ width: "48px", height: "48px" }} />
          <div style={{ fontWeight: "700", fontSize: "18px" }}>Agility</div>
          {/* <div style={{ fontWeight: "400", fontSize: "12px" }}>
            A wonderful serenity has taken like these sweet mornings of spring
            which I enjoy with.
          </div> */}
        </div>
      </div>
    </div>
  );
}
