import React, { useState, useEffect, useRef } from "react";
import "./LoanTypeComp.scss";
import Image1 from "../../images/loan-type-1.svg";
import Image2 from "../../images/dizbuzzplan.jpg";
import Image3 from "../../images/loan-type-3.svg";
import Image4 from "../../images/dizbuzzplan3.jpg";
import TestimonyDots from "../TestimonyCarousel/TestimonyDots";
import "./LoanTypeComp.scss";
import "./slider.scss"
import ImageWrapper from "./ImageWrapper";
let Carousel = require('react-responsive-carousel').Carousel;

export default function LoanTypeComp() {
  const [Counter1, setCounter1] = useState(0);
  const [Counter2, setCounter2] = useState(1);
  const [Counter3, setCounter3] = useState(2);
  const [Counter4, setCounter4] = useState(3);

  const [Counter, setCounter] = useState(0);
  React.useEffect(() => {
    const id = setInterval(() => {
      
    }, 2000);
    return () => {
      clearInterval(id);
    };
  }, [Counter]);

  const loanTypeCardContent = [
    {
      title: "Personal Loans",
      subTitle:
        "A quick fix for individuals who need cash in a flash. We finance Home Improvements, House Rent, Car Purchase, Medical Bills, School Fees, Debt Consolidation, others.",
      image: Image1,
    },
    {
      title: "Business Loans ",
      subTitle: "We bridge working capital and fund expansion.",
      image: Image2,
    },
    {
      title: "Financial Advisory",
      subTitle:
        "We deploy our industry experience to provide comprehensive financial, economic and strategic advice to businesses.",
      image: Image3,
    },
    {
      title: "Payroll Management ",
      subTitle:
        "Whether you have a single paid employee or run a small business with many employees, we can manage your payroll.",
      image: Image4,
    },
  ];

  

  const handleNext = () => {
    if (3 === Counter) {
      setCounter(0);
    } else {
      setCounter(Counter + 1);
    }
  };
  const handlePrev = () => {
    if (Counter === 0) {
      setCounter(0);
    } else {
      setCounter(Counter - 1);
    }
  };

  useEffect(() => {
    if (Counter === 0) {
      setCounter1(0);
      setCounter2(1);
      setCounter3(2);
      setCounter4(3);
    } else if (Counter === 1) {
      setCounter1(1);
      setCounter2(2);
      setCounter3(3);

      setCounter4(0);
    } else if (Counter === 2) {
      setCounter1(2);
      setCounter2(3);

      setCounter3(0);
      setCounter4(1);
    } else if (Counter === 3) {
      setCounter1(3);

      setCounter2(0);
      setCounter3(1);
      setCounter4(2);
    }
  }, [Counter]);
  return (
    <div style={{ display:"flex", flex:1 }}>
      <div>
        
      <div className="scrolling">
        <div className="people_scroll" style={{display :"flex", width:"fit-content"}}>
          <ImageWrapper
              title={loanTypeCardContent[Counter1].title}
              subTitle={loanTypeCardContent[Counter1].subTitle}
              image={loanTypeCardContent[Counter1].image}
          />
          <ImageWrapper
              title={loanTypeCardContent[Counter2].title}
              subTitle={loanTypeCardContent[Counter2].subTitle}
              image={loanTypeCardContent[Counter2].image}
             />
          <ImageWrapper
              className="gallery-cell slide"
              title={loanTypeCardContent[Counter3].title}
              subTitle={loanTypeCardContent[Counter3].subTitle}
              image={loanTypeCardContent[Counter3].image}
          />
          <ImageWrapper
              className="gallery-cell slide"
              title={loanTypeCardContent[Counter4].title}
              subTitle={loanTypeCardContent[Counter4].subTitle}
              image={loanTypeCardContent[Counter4].image}
          />
      </div>
        <div className="people_scroll" style={{display :"flex", width:"fit-content"}}>
          <ImageWrapper
              title={loanTypeCardContent[Counter1].title}
              subTitle={loanTypeCardContent[Counter1].subTitle}
              image={loanTypeCardContent[Counter1].image}
          />
          <ImageWrapper
              title={loanTypeCardContent[Counter2].title}
              subTitle={loanTypeCardContent[Counter2].subTitle}
              image={loanTypeCardContent[Counter2].image}
             />
          <ImageWrapper
              className="gallery-cell slide"
              title={loanTypeCardContent[Counter3].title}
              subTitle={loanTypeCardContent[Counter3].subTitle}
              image={loanTypeCardContent[Counter3].image}
          />
          <ImageWrapper
              className="gallery-cell slide"
              title={loanTypeCardContent[Counter4].title}
              subTitle={loanTypeCardContent[Counter4].subTitle}
              image={loanTypeCardContent[Counter4].image}
          />
      </div>
      </div>
      </div>
      </div>

  );
}
