import React from "react";
import AboutUsImage from "../../images/vector.jpg";
export default function AboutContentConp() {
  return (
    <div>
      <div className="about-content-cover-div">
        <div className="row about-us-image-and-content-div">
          <div className="col-md-6 col-12">
            <img
              src={AboutUsImage}
              alt=""
              style={{ width: "100%", height: "auto" }}
            />
          </div>
          <div className="col-md-6 col-12">
            <div className="about-title-content-div">
              We have your needs figured out
            </div>
            
            <div className="about-us-content-div">
              <p>
                We are an online financial services provider. Never worry again
                about that unexpected need. Get instant access to cash on demand
                with our 24/7 real-time support.
              </p>
              <p>
                At Dizbuzz we believe in putting the customer first. We
                understand the need and desire for those wanting short term and
                instalment loans from a lender they can trust. As a result, we
                have developed our lending model around this principle.
              </p>
              <p>
                We are all about creating long term sustainable relationships
                with our customers. We want you to feel at ease when dealing
                with us in the comfort of knowing that we are here to help.
              </p>
              <p>
                We analyze individual financial habits, identify needs and
                deliver unique affordable products, all these with the speed we
                represent. Our vision is to redefine service delivery and become
                the number one lending company of choice.
              </p>
            </div>
          </div>
          {/* <div className="col-md-6 col-12">
            <img
              src={AboutUsImage}
              alt=""
              style={{ width: "100%", height: "auto" }}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
}
