import React from "react";
import AppBanner from "../../components/AppBanner";
import AppFormFooter from "../../components/AppFormFooter";
import CarouselComp from "../../components/carousel";
import CarouselFormCover from "../../components/CarouselFormCover";
import GetALoan from "../../components/getALoan";
import LearnMore from "../../components/LearnMore";
import LoanCalculator from "../../components/LoanCalculator";
import LoanTypeComp from "../../components/LoanTypeComponent";
import ServicesCopm from "../../components/servicesComponentCard";
import TestimoneyCarousel from "../../components/TestimonyCarousel";
import WeAreHereToHelp from "../../components/WeAreHereToHelp";
import "./LandingPage.scss";
export default function LandingPage() {
  return (
    <div className="landing-page-cover">
      <CarouselComp />
      {/* <div className="tab-and-mobile-carousel-form">
        <CarouselFormCover />
      </div> */}
      {/* <ServicesCopm /> */}
      <WeAreHereToHelp />
      <LoanTypeComp />
      <GetALoan />
      <LearnMore />
      {/* <LoanCalculator /> */}
      <ServicesCopm specialBackground={"white"} hasTitle />

   
      <TestimoneyCarousel />
      <AppBanner />
      <AppFormFooter />
    </div>
  );
}
