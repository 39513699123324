import React from "react";

import "./AppFormFooter.scss";
import Logo from "../../images/dizbuz-logo.svg";
import Twitter from "../../images/twitter.svg";
import Facebook from "../../images/facebook.svg";
import Instagram from "../../images/instagram.svg";
import Linkedin from "../../images/linkedin.svg";
import StyledButton from "../styledButton";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export default function AppFormFooter({ isAboutUs }) {
  const navigate = useNavigate();
  const footerInformation = [
    { id: "1", title: "Sign Up", route: "/register" },
    { id: "2", title: "Login", route: "/login" },
  ];
  const footerCompany = [
    { id: "2", title: "Our Vision", route: "/about#vision" },
    { id: "3", title: "Our Mission", route: "/about/#mission" },
    { id: "4", title: "Values", route: "/about#values/#values" },
  ];
  const footerGetInTouch = [
    { id: "1", title: "Stay connected with us and let's know more stories" },
  ];
  // const footerLinks = [
  //   { id: "1", title: "Login" },
  //   { id: "2", title: "Apply for a loan" },
  //   { id: "3", title: "About Us", route: "/about" },
  //   { id: "4", title: "Contact Us", route: "/contact" },
  // ];
  const handleNavigate = (route) => {
    if (route === "/login") {
      window.location.href = "https://loans.dizbuzz.com/login";
    } else if (route === "/register") {
      window.location.href = "https://loans.dizbuzz.com/register";
    }
  };
  return (
    <div className="app-form-footer-div">
      {isAboutUs ? (
        <>
          <div className="row m-0 about-us-contact-wrapper">
            <div className="col-12 app-footer-question-content">
              <div className="app-footer-title-content">
                If you have questions
              </div>
              <div className="app-footer-subtitle-content">
                It's so easy to get in touch with us.
              </div>
            </div>
            <div className="col-md col-12 about-us-contact-button-wrapper">
              <StyledButton
                border={"none"}
                text={"Contact Us"}
                background={"#47B638"}
                color={"white"}
                height={"58px"}
                width={"164px"}
                fontSize={"16px"}
                fontFamily={"sen-bold"}
                borderRadius={"10px"}
                className={"footer-button-contact-us"}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="row p-0 m-0 question-and-button-div">
            <div className="col-md-8 col-12 app-footer-question-content">
              <div className="app-footer-title-content">
                If you have questions
              </div>
              <div className="app-footer-subtitle-content">
                It's so easy to get in touch with us.
              </div>
            </div>
            <div className="col-md col-12 app-footer-button">
              <StyledButton
                border={"none"}
                text={"Contact Us"}
                background={"#47B638"}
                color={"white"}
                height={"58px"}
                width={"164px"}
                fontSize={"16px"}
                fontFamily={"sen-bold"}
                borderRadius={"10px"}
                className={"footer-button-contact-us"}
              />
            </div>
          </div>
        </>
      )}

      <div className="app-footer-form-logo">
        <div className="app-footer-container">
          <div
            className="col-md-5 col-12"
            style={{ display: "flex" }}
            onClick={() => navigate("/")}
          >
            <img src={Logo} alt="" style={{ width: "48px", height: "48px" }} />
            <span
              style={{
                fontSize: "34px",
                lineHeight: "40.91px",
                fontFamily: "sen-bold",
                marginLeft: "32px",
                color: "#ffffff",
              }}
            >
              Dizbuzz
            </span>
          </div>
          <div className="col-md col-12 footer-mobile-link-div">
            <div className="footer-content-container">
              <div className="col-md-4 col-12">
                <ul
                  style={{ listStyle: "none", margin: "0px", padding: "0px" }}
                >
                  <li
                    style={{
                      fontSize: "24px",
                      fontWeight: "600",
                      lineHeight: "32px",
                      color: "#ffffff",
                      fontFamily: "Inter",
                      padding: "12px 0px",
                    }}
                  >
                    Information
                  </li>
                  {footerInformation?.map((list) => (
                    <li
                      style={{
                        fontSize: "14px",
                        lineHeight: "20.35px",
                        letterSpacing: "-0.28px",
                        color: "black",
                        fontFamily: "sen-light",
                        cursor: "pointer",
                        color: "#ffffff",
                        padding: "6px 0px",
                      }}
                      onClick={() => handleNavigate(list?.route)}
                    >
                      {list?.title}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-md-4 col-12">
                <ul
                  style={{ listStyle: "none", margin: "0px", padding: "0px" }}
                >
                  <li
                    style={{
                      fontSize: "24px",
                      fontWeight: "600",
                      lineHeight: "32px",
                      color: "#ffffff",
                      fontFamily: "Inter",
                      padding: "12px 0px",
                    }}
                  >
                    Company
                  </li>
                  <li
                    style={{
                      fontSize: "14px",
                      lineHeight: "20.35px",
                      letterSpacing: "-0.28px",
                      color: "black",
                      fontFamily: "sen-light",
                      cursor: "pointer",
                      color: "#ffffff",
                      padding: "6px 0px",
                    }}
                    onClick={() => navigate("/about")}
                  >
                    About
                  </li>
                  {footerCompany?.map((list) => (
                    <li
                      style={{
                        fontSize: "14px",
                        lineHeight: "20.35px",
                        letterSpacing: "-0.28px",
                        color: "black",
                        fontFamily: "sen-light",
                        cursor: "pointer",
                        color: "#ffffff",
                        padding: "6px 0px",
                      }}
                    >
                      <HashLink
                        style={{
                          color: "#ffffff",
                          textDecoration: "none",
                          fontSize: "14px",
                          lineHeight: "20.35px",
                          letterSpacing: "-0.28px",
                          fontFamily: "sen-light",
                          padding: "6px 0px",
                        }}
                        to={list.route}
                      >
                        {list.title}
                      </HashLink>
                    </li>
                  ))}
                  <li
                    style={{
                      fontSize: "14px",
                      lineHeight: "20.35px",
                      letterSpacing: "-0.28px",
                      color: "black",
                      fontFamily: "sen-light",
                      cursor: "pointer",
                      color: "#ffffff",
                      padding: "6px 0px",
                    }}
                    onClick={() => navigate("/contact")}
                  >
                    Contact
                  </li>
                </ul>
              </div>
              <div className="col-md-4 col-12">
                <ul
                  style={{ listStyle: "none", margin: "0px", padding: "0px" }}
                >
                  <li
                    style={{
                      fontSize: "24px",
                      fontWeight: "600",
                      lineHeight: "32px",
                      color: "#ffffff",
                      fontFamily: "Inter",
                      padding: "12px 0px",
                    }}
                  >
                    Get In Touch
                  </li>
                  {footerGetInTouch?.map((list) => (
                    <li
                      style={{
                        fontSize: "14px",
                        lineHeight: "20.35px",
                        letterSpacing: "-0.28px",
                        color: "black",
                        fontFamily: "sen-light",
                        cursor: "pointer",
                        color: "#ffffff",
                        padding: "6px 0px",
                      }}
                      onClick={() => navigate(list?.route)}
                    >
                      {list?.title}
                    </li>
                  ))}
                </ul>
                <div className="social-media-icon-container">
                 <div style={{ flex: 1 }}>
                     <a href="https://www.facebook.com/dizbuzzed"> <img
                      src={Facebook}
                      alt=""
                      style={{ width: "16px", height: "16px" }}
                    /> </a>
                  </div>
                  <div style={{ flex: 1 }}>
                     <a href="https://twitter.com/Dizbuzzed"><img
                      src={Twitter}
                      alt=""
                      style={{ width: "16px", height: "16px" }}
                    /></a>
                  </div> 
                  <div style={{ flex: 1 }}>
                   <a href="https://www.instagram.com/dizbuzzed"> <img
                      
                      src={Instagram}
                      alt=""
                      style={{ width: "16px", height: "16px" }}
                    /></a>
                  </div> 
            
                  <div style={{ flex: 1 }}>
                    <a href="https://www.linkedin.com/company/dizbuzz"> <img
                      src={Linkedin}
                      alt=""
                      style={{ width: "24px", height: "24px" }}
                    /></a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md"
              style={{
                color: "#ffffff",
                // textAlign: "center",
                marginTop: "48px",
              }}
            >
              © Dizbuzz 2023 all rights reserved.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
