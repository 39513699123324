import React from "react";
import AboutUsBannerComp from "../../components/AboutUsBannerComp";
import AppBanner from "../../components/AppBanner";
import AppFormFooter from "../../components/AppFormFooter";
import OurValues from "../../components/OurValues";
import VisionAndMission from "../../components/VisionAndMission";
import "./About.scss";
import AboutContentConp from "./AboutContentConp";
export default function AboutUs() {
  return (
    <div className="about-us-div-cover">
      <AboutUsBannerComp text={"About Us"} />
      <AboutContentConp />
      <VisionAndMission />
      <OurValues />
      <AppBanner />
      <AppFormFooter isAboutUs />
    </div>
  );
}
