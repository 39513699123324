export default class services {
  async getService(pathName, token) {
    try {
      let response = await fetch(`${pathName}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `${token}`,
        },
        // body: JSON.stringify({
        //   principal: 1000000,
        //   interest: 5,
        //   loan_duration: 12,
        //   method: "FLAT_RATE",
        //   interest_period: "PER_MONTH",
        //   repay_cycle: "WEEKLY",
        //   loan_period: "MONTHS",
        // }),
      });

      return await response.json();
    } catch (err) {
      throw err;
    }
  }

  async postService(pathName, token, body) {
    try {
      let response = await fetch(`${pathName}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify(body),
      });

      return await response.json();
    } catch (err) {
      throw err;
    }
  }
}
