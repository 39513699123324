import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import services from "../../services";
import { formatToCurrency } from "../../utility";
import AppFormCover from "../AppFormComponent";
import SelectComponent from "../SelectComponent";
import StyledButton from "../styledButton";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import "./CarouselFormCover.scss";
import { useFormik, ErrorMessage } from "formik";
export default function CarouselFormCover() {
    const [error, setError] = useState();
    // const loanAmoutRef = React.useRef();
    const formik = useFormik({
        initialValues: {
            loanAmount: "0",
            duration: 0,
        },
        validationSchema: yup.object({
            loanAmount: yup.string(),
            duration: yup
                .number()
                .positive("please enter a positive number")
                .integer("please enter a non-fraction number")
                .moreThan(0, "duration should be greater than 0")
                .lessThan(13, "duration should be less than 12"),
        }),
        onSubmit: (values) => {
            console.log(values);
        },
    });

    const handleChange = (evt) => {
        if (formik.errors.loanAmount) {
            setloanCalculatorOptions({
                ...loanCalculatorOptions,
                loanAmount: evt.target.value,
            });

            formik.setFieldValue("loanAmount", evt.target.value);
        } else {
            let input = evt.target.value;
            input = input.replace(/[\D\s\._\-]+/g, "");
            input = input ? parseInt(input, 10) : 0;
            setloanCalculatorOptions({
                ...loanCalculatorOptions,
                loanAmount: input === 0 ? "" : input.toLocaleString("en-US"),
            });

            formik.setFieldValue(
                "loanAmount",
                input === 0 ? "" : input.toLocaleString("en-US")
            );
        }
    };

    const [loanCalculatorOptions, setloanCalculatorOptions] = useState({
        loanAmount: "",
        duration: "",
        monthlyRepayment: "",
        loanProduct: "",
        interest: "",
        interest_period: "",
        loan_period: "",
        method: "",
        repay_cycle: "",
    });

    const [loanOption, setloanOption] = useState([]);
    const [totalPayment, setTotalPayment] = useState(0);

    const navigate = useNavigate();
    const token =
        "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Ijk4OGIwNGFiLTVjODQtNDAwOS1hODk5LTUwMTQ5OTgyNTc1NCIsInVzZXJfdHlwZSI6IkNVU1RPTUVSIiwiaWF0IjoxNjk5ODE5OTU4LCJleHAiOjMxMTIwOTk4MTk5NTh9.lDTILnhUJkruBExNmM-W_HobzoQtgEO6u7iDG1O8mxU";
    useEffect(() => {
        const getDataFromServer = new services().getService(
            "https://dizbuzz-loan-backend.onrender.com/api/v1/loan/loan-product",
            token
        );
        getDataFromServer
            .then((res) => {
                const distructureData = res?.data?.map((list) => {
                    return { name: list?.product_name, ...list };
                });
                setloanOption(distructureData);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);
    useEffect(() => {
        const setLoanProductSelected = loanOption?.find(
            (list) => list?.name === loanCalculatorOptions?.loanProduct
        );
        // if (
        //   loanCalculatorOptions?.loanAmount === "" ||
        //   loanCalculatorOptions?.duration === "" ||
        //   loanCalculatorOptions?.loanProduct === ""
        // ) {
        //   setMinimumPrincipal("");
        // } else if (
        //   loanCalculatorOptions?.duration <= 0 ||
        //   loanCalculatorOptions?.duration > 12 ||
        //   loanCalculatorOptions?.loanAmount === 0 ||
        //   loanCalculatorOptions?.loanAmount <= 0
        // ) {
        //   setMinimumPrincipal("");
        // } else {
        //   setMinimumPrincipal(setLoanProductSelected?.minimum_principal);
        // }
        if (setLoanProductSelected) {
            const loanCalculator = new services().postService(
                "https://dizbuzz-loan-backend.onrender.com/api/v1/repayments/calculator",
                token,
                {
                    principal: parseInt(
                        loanCalculatorOptions.loanAmount.replace(/,/g, "")
                    ),
                    loan_duration: loanCalculatorOptions.duration,
                    interest: setLoanProductSelected.interest,
                    interest_period: setLoanProductSelected.interest_period,
                    loan_period: setLoanProductSelected.loan_duration_period,
                    method: setLoanProductSelected.interest_method,
                    repay_cycle: setLoanProductSelected.repay_cycle[0],
                }
            );
            loanCalculator
                .then((res) => setTotalPayment(res.total_payment))
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [
        loanCalculatorOptions?.loanAmount,
        loanCalculatorOptions?.duration,
        loanCalculatorOptions?.loanProduct,
    ]);
    return (
        <div className="carousel-form-cover-background">
            <div className="row m-0 p-0">
                {formik.errors.duration && (
                    <div className="error">{formik.errors.duration}</div>
                )}
                {formik.errors.loanAmount && (
                    <div className="error">{formik.errors.loanAmount}</div>
                )}

                <form className="form-wrapper">
                    <div className="col-lg-12 col-md-12 col-12 m-0 p-0 carousel-form-main-cover">
                        <div className="row">
                            <div className="col-xl col-md-6 col-12 margin-bottom-for-tab-and-mobile">
                                <span>Select Loan type</span>
                                <SelectComponent
                                    placeholder={"Click to select"}
                                    height={"51px"}
                                    width={"100%"}
                                    selectDetails={loanOption}
                                    fontFamily={"sen-medium"}
                                    fontSize={"16px"}
                                    paddingLeft={"6px"}
                                    border={"2px solid #47B638"}
                                    borderColor={"#47B638"}
                                    color={""}
                                    value={loanCalculatorOptions?.loanProduct}
                                    onChange={(evt) =>
                                        setloanCalculatorOptions({
                                            ...loanCalculatorOptions,
                                            loanProduct: evt.target.value,
                                        })
                                    }
                                />
                            </div>

                            <div className="col-xl col-md-6 col-12 margin-bottom-for-tab-and-mobile">
                                <span>Enter loan amount</span>
                                <div className="illusion-input">
                                    <div className="currency-container">₦</div>
                                    <AppFormCover
                                        // ref={loanAmoutRef}
                                        height={"48px"}
                                        width={"100%"}
                                        placeholder={"Eg. 500000"}
                                        fontFamily={"sen-medium"}
                                        fontSize={"16px"}
                                        paddingLeft={"42px"}
                                        border={"none"}
                                        borderColor={"transparent !important"}
                                        value={
                                            formik.errors.loanAmount
                                                ? loanCalculatorOptions?.loanAmount
                                                : loanCalculatorOptions.loanAmount
                                        }
                                        onChange={(evt) => {
                                            handleChange(evt);
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="col-xl col-md-6 col-12 margin-bottom-for-tab-and-mobile">
                                <span>Enter Duration</span>

                                <AppFormCover
                                    height={"51px"}
                                    width={"100%"}
                                    placeholder={"Eg. 1"}
                                    fontFamily={"sen-medium"}
                                    paddingLeft={"6px"}
                                    fontSize={"16px"}
                                    border={"2px solid #47B638"}
                                    borderColor={"#47B638"}
                                    value={loanCalculatorOptions?.duration}
                                    onChange={(evt) => {
                                        setloanCalculatorOptions({
                                            ...loanCalculatorOptions,
                                            duration: evt.target.value,
                                        });
                                        formik.setFieldValue(
                                            "duration",
                                            evt.target.value
                                        );
                                    }}
                                />
                            </div>

                            {!!totalPayment && (
                                <div className="col-xl col-md-6 col-12 margin-bottom-for-tab-and-mobile">
                                    <span>Monthly Payment</span>
                                    <AppFormCover
                                        height={"51px"}
                                        width={"100%"}
                                        placeholder={"Amount"}
                                        fontFamily={"sen-medium"}
                                        fontSize={"16px"}
                                        paddingLeft={"23px"}
                                        border={"2px solid #47B638"}
                                        borderColor={"#47B638"}
                                        value={formatToCurrency(
                                            Math.trunc(
                                                totalPayment /
                                                    loanCalculatorOptions?.duration
                                            )
                                        )}
                                        disabled
                                    />
                                </div>
                            )}

                            {!!totalPayment && (
                                <div className="col-xl col-md-6 col-12 margin-bottom-for-tab-and-mobile">
                                    <span>Total Payment</span>
                                    <AppFormCover
                                        height={"51px"}
                                        width={"100%"}
                                        placeholder={"Amount"}
                                        fontFamily={"sen-medium"}
                                        fontSize={"16px"}
                                        paddingLeft={"23px"}
                                        border={"2px solid #47B638"}
                                        borderColor={"#47B638"}
                                        value={formatToCurrency(
                                            Math.trunc(totalPayment)
                                        )}
                                        disabled
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </form>

                <div className="col-lg-2 col-md-12 col-12 m-0 p-0 carousel-form-main-button-cover">
                    <StyledButton
                        className={"has-mobile-button-width"}
                        text={"Loan Calculator"}
                        height={"51px"}
                        color={"white"}
                        background={"#47B638"}
                        borderRadius={"5px"}
                        fontSize={"16px"}
                        fontFamily={"sen-bold"}
                        border={"none"}
                        width={"150px"}
                        handleClick={() =>
                            window.open("https://loans.dizbuzz.com", "_self")
                        }
                    />
                </div>
            </div>
        </div>
    );
}
