import React from "react";
import { useNavigate } from "react-router-dom";
import { clearScroll } from "../../utility";
import "./SidebarComp.scss";
export default function SideBar({ handleCloseNav }) {
  const navigate = useNavigate();
  const routers = [
    { id: "", title: "Home", route: "/" },
    { id: "", title: "Apply", route: "/apply" },
    { id: "", title: "About Us", route: "/about" },
    { id: "", title: "Contact", route: "/contact" },
    { id: "", title: "Login", route: "/login" },
    { id: "", title: "Get started", route: "/register", hasButton: true },
  ];
  const handleRoute = (route) => {
    if (route === "/apply") {
      handleCloseNav();
      clearScroll();
      window.location.href = "https://loans.dizbuzz.com/register";
    } else if (route === "/login") {
      handleCloseNav();
      clearScroll();
      window.location.href = "https://loans.dizbuzz.com/login";
    } else if (route === "/register") {
      handleCloseNav();
      clearScroll();
      window.location.href = "https://loans.dizbuzz.com/register";
    } else {
      handleCloseNav();
      navigate(route);
      clearScroll();
    }
  };

  return (
    <div className="sidebar-cover">
      <ul className="sidebar-cover-ul">
        {routers?.map((list) => (
          <li className="sidebar-cover-ul-li">
            <span onClick={() => handleRoute(list?.route)}>{list?.title}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}
