import React from "react";
import AppFormCover from "../../components/AppFormComponent";
import SelectComponent from "../../components/SelectComponent";
import StyledButton from "../../components/styledButton";
import TextArea from "../../components/TextAreaComp";
import Phone from "../../images/phone.svg";
import Social from "../../images/socialdz.svg";
import Facebook from "../../images/facebook.svg";
import Message from "../../images/message.svg";
import Instagram from "../../images/instagram.svg";
import Linkedin from "../../images/linkedin.svg";
import Twitter from "../../images/twitter.svg";
import ContactTelePhone from "../../images/telephone-contact.svg";
export default function ContactUsFormComp() {
    return (
        <div className="contact-us-form-cover">
            <div className="contact-us-wrapper">
                <div className="contact-us-text-container">
                    <div className="contact-us-text">Contact Us</div>
                </div>
                <div className="contact-us-cards-wrapper">
                    <div className="contact-us-card-one">
                        <img
                            src={Phone}
                            style={{ width: "48px", height: "48px" }}
                        />
                        <div style={{ fontWeight: "700", fontSize: "24px" }}>
                            Phone
                        </div>
                        <div style={{ fontWeight: "400", fontSize: "18px" }}>
                            8AM to 4PM; Monday - Friday
                        </div>
                        <div style={{ fontWeight: "500", fontSize: "18px" }}>
                            <a href="tel:+234 913 195 7938">
                                +234 913 195 7938
                            </a>
                        </div>
                    </div>
                    <div className="contact-us-card-two">
                        <img
                            src={Message}
                            style={{ width: "48px", height: "48px" }}
                        />
                        <div style={{ fontWeight: "700", fontSize: "24px" }}>
                            Email
                        </div>
                        <div
                            style={{
                                fontWeight: "400",
                                fontSize: "18px",
                                display: "flex",
                                justifyContent: "center",
                                width: "100%",
                                textAlign: "center",
                            }}
                        >
                            <p>
                                Send us an email and our team will respond
                                within the hour.
                            </p>
                        </div>
                        <div style={{ fontWeight: "500", fontSize: "18px" }}>
                            <a href="mailto:care@dizbuzz.com">
                                care@dizbuzz.com
                            </a>
                        </div>
                    </div>
                    <div className="contact-us-card-three">
                        <img
                            src={Social}
                            style={{ width: "48px", height: "48px" }}
                        />
                        <div style={{ fontWeight: "700", fontSize: "24px" }}>
                            Connect with us
                        </div>
                        <div style={{ display: "flex", columnGap: "12px" }}>
                            <div style={{ flex: 1 }}>
                                <a
                                    target="_blank"
                                    href="https://www.facebook.com/dizbuzzed/"
                                >
                                    <img
                                        src={Facebook}
                                        alt=""
                                        style={{
                                            width: "16px",
                                            height: "16px",
                                        }}
                                    />
                                </a>
                            </div>
                            <div style={{ flex: 1 }}>
                                <a
                                    target="_blank"
                                    href="https://twitter.com/dizbuzzed"
                                >
                                    <img
                                        src={Twitter}
                                        alt=""
                                        style={{
                                            width: "16px",
                                            height: "16px",
                                        }}
                                    />
                                </a>
                            </div>
                            <div style={{ flex: 1 }}>
                                <a
                                    target="_blank"
                                    href="https://instagram.com/dizbuzzed"
                                >
                                    <img
                                        src={Instagram}
                                        alt=""
                                        style={{
                                            width: "16px",
                                            height: "16px",
                                        }}
                                    />
                                </a>
                            </div>
                            <div style={{ flex: 1 }}>
                                <a
                                    target="_blank"
                                    href="https://ng.linkedin.com/company/dizbuzz"
                                >
                                    <img
                                        src={Linkedin}
                                        alt=""
                                        style={{
                                            width: "24px",
                                            height: "24px",
                                        }}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className='col-md-5 col-12'>
                <img src={ContactUsImage} alt="" style={{width:"100%"}}/>
                </div> */}

            {/* <div className="form-title">Get in touch</div>
          <div className="contact-number-div">
            <img src={ContactTelePhone} alt="" />
            <span
              style={{
                fontSize: "18px",
                lineHeight: "32px",
                fontFamily: "sen-light",
                marginLeft: "15px",
              }}
            >
              09053077986; 08029486694
            </span>
          </div>
          <div className="contact-email-div">
            <img src={ContactTelePhone} alt="" />
            <span
              style={{
                fontSize: "18px",
                lineHeight: "32px",
                fontFamily: "sen-light",
                marginLeft: "15px",
              }}
            >
              care@dizbuzz.com
            </span>
          </div> */}
            <div className="contact-us-form-container">
                <div className="contact-us-form-wrapper">
                    <div
                        style={{
                            fontWeight: "700",
                            fontSize: "60px",
                            textAlign: "center",
                        }}
                    >
                        Send us a message
                    </div>
                    <div
                        style={{
                            fontWeight: "400",
                            fontSize: "18px",
                            textAlign: "center",
                            marginBottom: "24px",
                        }}
                    >
                        {/* Send us a message */}
                    </div>
                    <div className="personal-field-input">
                        <div
                            className="name-input-wrapper"
                            style={{ marginBottom: "14px" }}
                        >
                            <AppFormCover
                                placeholder={"Name"}
                                height={"100px"}
                                paddingLeft={"39px"}
                                border={"1px solid #B1BACB"}
                                borderColor={"transparent"}
                                background={"rgba(255, 255, 255, 0.3)"}
                                color={"#ffffff"}
                            />
                        </div>
                        <div
                            className="email-input-wrapper"
                            style={{ marginBottom: "14px" }}
                        >
                            <AppFormCover
                                type={"email"}
                                placeholder={"Email"}
                                height={"100px"}
                                paddingLeft={"39px"}
                                border={"1px solid #B1BACB"}
                                borderColor={"transparent"}
                                background={"rgba(255, 255, 255, 0.3)"}
                                color={"#ffffff"}
                            />
                        </div>
                    </div>
                    <div style={{ marginBottom: "14px" }}>
                        {/* <AppFormCover placeholder={"Inquiry"} height={"100px"} paddingLeft={"39px"} border={"1px solid #B1BACB"} borderColor={"transparent"}/> */}
                        <SelectComponent
                            placeholder={"Inquiry"}
                            paddingLeft={"39px"}
                            height={"100px"}
                            border={"1px solid #B1BACB"}
                            borderColor={"transparent"}
                            fontFamily={"sen-light"}
                            color={"#6d757d"}
                            background={"rgba(255, 255, 255, 0.3)"}
                        />
                    </div>
                    <div style={{ marginBottom: "14px" }}>
                        <TextArea
                            placeholder={"Details"}
                            paddingLeft={"39px"}
                            border={"1px solid #B1BACB"}
                            borderColor={"transparent"}
                            paddingTop={"35px"}
                            color={"#ffffff"}
                            background={"rgba(255, 255, 255, 0.3)"}
                        />
                    </div>
                    <div className="contact-us-button-div">
                        <StyledButton
                            color={"white"}
                            fontFamily={"sen-bold"}
                            fontSize={"16px"}
                            height={"65px"}
                            width={"150px"}
                            background={"#47B638"}
                            borderRadius={"10px"}
                            text={"Submit"}
                            border={"none"}
                            className={"contact-use-button-mobile-style"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
