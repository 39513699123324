import React from "react";
import "./StyledButton.scss";
export default function StyledButton({
  height,
  width,
  background,
  color,
  fontSize,
  text,
  border,
  fontFamily,
  borderRadius,
  className,
  handleClick,
}) {
  return (
    <button
      className={className}
      style={{
        height,
        width,
        background,
        color,
        fontSize,
        border,
        fontFamily,
        borderRadius,
      }}
      onClick={() => handleClick()}
    >
      {text}
    </button>
  );
}
