import React from "react";
import "./Navbar.scss";
import Logo from "../../images/dizbuz-logo.svg";
import StyledButton from "../styledButton";
import MenuIcon from "../../images/menu-icon.svg";
import { useNavigate } from "react-router-dom";
export default function Navbar({ handleToggle }) {
  const navigate = useNavigate();
  const routers = [
    { id: "", title: "Home", route: "/" },
    { id: "", title: "Apply", route: "/apply" },
    { id: "", title: "About Us", route: "/about" },
    { id: "", title: "Contact", route: "/contact" },
    { id: "", title: "Login", route: "/login" },
    { id: "", title: "Get started", route: "/register", hasButton: true },
  ];
  const handleNavigate = (route) => {
    if (route === "/apply") {
      window.location.href = "https://loans.dizbuzz.com/register";
    }
    if (route === "/login") {
      window.location.href = "https://loans.dizbuzz.com/login";
    } else if (route === "/register") {
      window.location.href = "https://loans.dizbuzz.com/register";
    } else {
      navigate(route);
    }
  };
  return (
    <div className="navbar-cover">
      <div className="nav-logo" onClick={() => navigate("/")}>
        <img src={Logo} alt="" className="nav-logo-img" />
      </div>
      <span className="logo-name" onClick={() => navigate("/")}>
        Dizbuzz
      </span>
      <div className="nav-list">
        <div className="menu-icon-div">
          <img
            src={MenuIcon}
            alt=""
            onClick={handleToggle}
            style={{ cursor: "pointer" }}
          />
        </div>
        <ul>
          {routers?.map((list) => (
            <li>
              {list?.hasButton ? (
                <StyledButton
                  text={list?.title}
                  border={"none"}
                  color={"white"}
                  background={"#47B638"}
                  borderRadius={"5px"}
                  height={"55px"}
                  width={"191px"}
                  fontFamily={"sen-bold"}
                  fontSize={"16px"}
                  handleClick={() => handleNavigate(list?.route)}
                />
              ) : (
                <span onClick={() => handleNavigate(list?.route)}>
                  {list?.title}
                </span>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
