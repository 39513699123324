export const formatToCurrency = amount => {
    return "₦" + parseInt(amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };
  export const fixScroll=()=>{
    var x = window.scrollX;
    var y = window.scrollY;
    window.onscroll = function () {
      window.scrollTo(x, y);
    };
  }
  export const clearScroll=()=>{
    window.onscroll = function () {};
  }