import React from "react";
import AppBanner from "../../components/AppBanner";
import AppFormFooter from "../../components/AppFormFooter";
import ContactUsBanner from "../../components/ContactUsBanner";
import TestimoneyCarousel from "../../components/TestimonyCarousel";
import AboutContentConp from "../About/AboutContentConp";
import "./ContactUs.scss";
import ContactUsFormComp from "./ContactUsFormComp";
export default function ContactUs() {
  return (
    <div className="contact-us-cover">
      <ContactUsBanner />
      <ContactUsFormComp />
      <TestimoneyCarousel />
      <AppBanner />
      <AppFormFooter />
    </div>
  );
}
