import React, { useEffect } from "react";
import StyledButton from "../styledButton";
import "./AppBanner.scss";
import AOS from "aos";
export default function AppBanner() {
  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 400, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
    });
  }, []);
  return (
    <div className="banner-cover">
      <div className="banner-title" data-aos="fade-in">
        Get an instant loan.
        <br className="banner-br" /> We dizbuzz loans easily
      </div>
      <div className="banner-subtitle-div">
        We have products tailored to your needs
      </div>
      <div className="banner-button-div" data-aos="fade-in">
        <StyledButton
          className={"banner-cover-button"}
          text={"Apply Now"}
          background={"#47B638"}
          border={"none"}
          color={"white"}
          borderRadius={"5px"}
          fontFamily={"sen-bold"}
          width={"243px"}
          height={"65px"}
        />
      </div>
    </div>
  );
}
