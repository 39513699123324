import "./App.scss";
import Navbar from "./components/Navbar";
import LandingPage from "./views/LandingPage";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AboutUs from "./views/About";
import ContactUs from "./views/ContactUs";
import SideBar from "./components/SidebarComp";
import { useState } from "react";
import { clearScroll, fixScroll } from "./utility";
function App() {
  const [showSideMenu, setshowSideMenu] = useState(false);
  const handleToggle = () => {
    if (showSideMenu) {
      setshowSideMenu(false);
      clearScroll();
    } else {
      setshowSideMenu(true);
      fixScroll();
    }
  };
  return (
    <Router>
      <div className="App app-max-width">
        <Navbar handleToggle={handleToggle} />
        {showSideMenu && <SideBar handleCloseNav={handleToggle} />}
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
