import React, { useEffect } from "react";
import ContentDiv from "../ContentDiv";
import "./WeAreHereToHelp.scss";
import Icon1 from "../../images/payday.svg";
import Icon2 from "../../images/car-icon1.svg";
import Icon3 from "../../images/study-loan-icon2.svg";
import Icon4 from "../../images/globe-travel.svg";
import AOS from "aos";
import CarouselFormCover from "../CarouselFormCover";
export default function WeAreHereToHelp() {
  const conDetails = [
    { title: "Payday loan", icon: Icon1 },
    { title: "Car loan", icon: Icon2 },
    { title: "Study loan", icon: Icon3 },
    { title: "Travel loan", icon: Icon4 },
  ];
  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 400, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
    });
  }, []);
  return (
    <div className="we-are-here-to-help">
      <div className="loadCalculatorMobile">
        <CarouselFormCover />
      </div>
      <div className="we-are-here-title-cover" data-aos="fade-in">
        <ContentDiv
          className={"we-are-here-to-help-title"}
          text={"We’re here to help you when you need financial support"}
          textAlign={"center"}
          fontSize={"60px"}
          lineHeight={"73px"}
          color={"#1D293F"}
          fontFamily={"sen-bold"}
          width={"90%"}
          marginBottom={"45px"}
        />
      </div>
      <ContentDiv
        text={"Access loan products designed with your needs in mind"}
        textAlign={"center"}
        fontSize={"18px"}
        lineHeight={"32px"}
        color={"#7C8087"}
        fontFamily={"sen-bold"}
        marginBottom={"79px"}
      />
      <div className="row">
        {conDetails?.map((list) => (
          <div
            className="col-lg-3 col-md-6 col-6 we-are-here-to-help-main-car-cover"
            data-aos="zoom-in"
          >
            <div className="we-are-here-card">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={list?.icon} alt="" />
              </div>
              <ContentDiv
                text={list?.title}
                color={"#000000"}
                textAlign={"center"}
                marginTop={"40px"}
                fontSize={"24px"}
                lineHeight={"28px"}
                fontFamily={"sen-bold"}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
