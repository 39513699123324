import React, { useState, useEffect } from "react";
import ClientCommentCard from "./ClientComment";
import "./TestimoneyCarousel.scss";
import "./scroller.css";
import User from "../../images/user-avater.svg";
import LoveIcon from "../../images/love-image.svg";
import TestimonyDots from "./TestimonyDots";
import AOS from "aos";
import ImageWrapper from "../LoanTypeComponent/ImageWrapper";
export default function TestimoneyCarousel() {
    const [Counter1, setCounter1] = useState(0);
    const [Counter2, setCounter2] = useState(1);
    const [Counter3, setCounter3] = useState(2);
    const [Counter4, setCounter4] = useState(3);

    const [Counter, setCounter] = useState(0);
    React.useEffect(() => {
        const id = setInterval(() => {
            // handleNext();
        }, 2000);
        return () => {
            clearInterval(id);
        };
    }, [Counter]);
    const userCommentsDetails = [
        {
            id: "",
            title: "Great service, efficient communication and a really easy way to get a quick loan for my child's school fees.",
            name: " - John Ikenna",
            // image: User,
        },
        {
            id: "",
            title: "They were very helpful in answering questions as well as helping in coming up with a suitable repayment plan. Highly rated service...",
            name: " - Jude Onome",
            // image: User,
        },
        {
            id: "",
            title: "Excellent Service! From my application to receipt of funds, the process was seamless. I recommend them.",
            name: " - Cynthia Okiti",
            // image: User,
        },
        {
            id: "",
            title: "These guys encourage responsible borrowing. They took care to ensure I had enough to cover my living expenses after settling my monthly loan obligations. That's service!",
            name: " - Sean Babatope",
            // image: User,
        },
        {
            id: "",
            title: "The service is smooth and straightforward. I will use again",
            name: " - Clifford Opeyemi",
            // image: User,
        },
    ];

    const handleNext = () => {
        if (3 === Counter) {
            setCounter(0);
        } else {
            setCounter(Counter + 1);
        }
    };
    const handlePrev = () => {
        if (Counter === 0) {
            setCounter(0);
        } else {
            setCounter(Counter - 1);
        }
    };

    useEffect(() => {
        if (Counter === 0) {
            setCounter1(0);
            setCounter2(1);
            setCounter3(2);
            setCounter4(3);
        } else if (Counter === 1) {
            setCounter1(1);
            setCounter2(2);
            setCounter3(3);

            setCounter4(0);
        } else if (Counter === 2) {
            setCounter1(2);
            setCounter2(3);

            setCounter3(0);
            setCounter4(1);
        } else if (Counter === 3) {
            setCounter1(3);

            setCounter2(0);
            setCounter3(1);
            setCounter4(2);
        }
    }, [Counter]);
    useEffect(() => {
        AOS.init({
            // Global settings:
            disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
            startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
            initClassName: "aos-init", // class applied after initialization
            animatedClassName: "aos-animate", // class applied on animation
            useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
            disableMutationObserver: false, // disables automatic mutations' detections (advanced)
            debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
            throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

            // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
            offset: 120, // offset (in px) from the original trigger point
            delay: 0, // values from 0 to 3000, with step 50ms
            duration: 400, // values from 0 to 3000, with step 50ms
            easing: "ease", // default easing for AOS animations
            once: false, // whether animation should happen only once - while scrolling down
            mirror: false, // whether elements should animate out while scrolling past them
            anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
        });
    }, []);

    return (
        <div>
            <div className="testimony-cover-page">
                <div className="love-image-div" data-aos="zoom-in">
                    <img src={LoveIcon} alt="" />
                </div>
                <div className="row">
                    <div
                        className="col-md-10 col-12 testimony-title-div"
                        data-aos="fade-in"
                    >
                        Don’t take our word for it. <br />
                        Read what our customers think
                    </div>
                </div>
            </div>
            <div className="scrolling">
                <div className="testimony_scroll" style={{ display: "flex" }}>
                    <ClientCommentCard
                        className="gallery-cell"
                        title={userCommentsDetails[Counter1].title}
                        name={userCommentsDetails[Counter1].name}
                        image={userCommentsDetails[Counter1].image}
                    />

                    <ClientCommentCard
                        className="gallery-cell"
                        title={userCommentsDetails[Counter2].title}
                        name={userCommentsDetails[Counter2].name}
                        image={userCommentsDetails[Counter2].image}
                    />
                    <ClientCommentCard
                        className="gallery-cell"
                        title={userCommentsDetails[Counter3].title}
                        name={userCommentsDetails[Counter3].name}
                        image={userCommentsDetails[Counter3].image}
                    />
                    <ClientCommentCard
                        className="gallery-cell"
                        title={userCommentsDetails[Counter4].title}
                        name={userCommentsDetails[Counter4].name}
                        image={userCommentsDetails[Counter4].image}
                    />
                    <ClientCommentCard
                        className="gallery-cell"
                        title={userCommentsDetails[Counter1].title}
                        name={userCommentsDetails[Counter1].name}
                        image={userCommentsDetails[Counter1].image}
                    />
                </div>
                <div className="testimony_scroll" style={{ display: "flex" }}>
                    <ClientCommentCard
                        className="gallery-cell"
                        title={userCommentsDetails[Counter1].title}
                        name={userCommentsDetails[Counter1].name}
                        image={userCommentsDetails[Counter1].image}
                    />

                    <ClientCommentCard
                        className="gallery-cell"
                        title={userCommentsDetails[Counter2].title}
                        name={userCommentsDetails[Counter2].name}
                        image={userCommentsDetails[Counter2].image}
                    />
                    <ClientCommentCard
                        className="gallery-cell"
                        title={userCommentsDetails[Counter3].title}
                        name={userCommentsDetails[Counter3].name}
                        image={userCommentsDetails[Counter3].image}
                    />
                    <ClientCommentCard
                        className="gallery-cell"
                        title={userCommentsDetails[Counter4].title}
                        name={userCommentsDetails[Counter4].name}
                        image={userCommentsDetails[Counter4].image}
                    />
                    <ClientCommentCard
                        className="gallery-cell"
                        title={userCommentsDetails[Counter1].title}
                        name={userCommentsDetails[Counter1].name}
                        image={userCommentsDetails[Counter1].image}
                    />
                </div>
            </div>
        </div>
    );
}
