import React, { useEffect } from "react";
import "./servicesCopm.scss";
import Icon1 from "../../images/service-icon1.svg";
import Icon2 from "../../images/service-icon2.svg";
import Icon3 from "../../images/service-icon3.svg";
import ContentDiv from "../ContentDiv";
import AOS from "aos";
export default function ServicesCopm({ specialBackground, hasTitle }) {
  const cardDetails = [
    {
      id: "",
      title: "We are flexible",
      subtitle: "You can save money on the interest by repaying your short term loan early.",
      icon: Icon1,
    },
    {
      id: "",
      title: "Transparency",
      subtitle:
        "No hidden charges. We make all our fees completely clear upfront before you apply.",
      icon: Icon2,
    },
    {
      id: "",
      title: "Instant Service",
      subtitle:
        "Once your loan is approved, \n we send you the money. \n At Dizbuzz, there are no stories\n \n ",
      icon: Icon2,
    },
  ];
  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 400, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
    });
  }, []);

  return (
    <div
      className={`${
        specialBackground ? "white-services-cover-div" : "services-cover-div"
      }`}
    >
      {hasTitle && (
        <div className="why-choose-dizbuzz-title">Why choose Dizbuzz</div>
      )}
      <div className="row">
        {cardDetails?.map((list) => (
          <div className="col-lg-4 col-md-6 services-icons-and-content-cover">
            <div
              className="services-icon-main-div service-card-margin-bottom"
              data-aos="zoom-out"
            >
              <img src={list?.icon} alt="" />
            </div>
            <div className="services-content-main-div" data-aos="fade-in">
              <ContentDiv
                text={list?.title}
                fontSize={"21px"}
                lineHeight={"34px"}
                color={"#1D293F"}
                fontFamily={"sen-bold"}
                marginBottom={"7px"}
              />
              <ContentDiv
                text={list?.subtitle}
                fontSize={"16px"}
                lineHeight={"20px"}
                color={"#7C8087"}
                fontFamily={"sen-bold"}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
