import React from "react";
import "./AppFormCover.scss";

export default function AppFormCover({
  type,
  placeholder,
  height,
  background,
  border,
  borderColor,
  value,
  onChange,
  disabled,
  borderRadius,
  fontSize,
  fontFamily,
  paddingLeft,
  color,
}) {
  return (
    <input
      className={`form-control mobile-font-size`}
      value={value}
      type={type}
      placeholder={placeholder}
      style={{
        height,
        background,
        border,
        borderColor,
        fontSize: fontSize ? fontSize : "24px",
        fontFamily: fontFamily ? fontFamily : "PT_Serif_Bold",
        paddingLeft: paddingLeft ? paddingLeft : "51px",
        borderRadius,
        color,
      }}
      onChange={onChange}
      disabled={disabled}
    />
  );
}
