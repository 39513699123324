import React from 'react'
import SellingWithOdioPayCover from '../SellingWithOdioPay'


export default function GetALoan() {
  return (
    <div>
      <SellingWithOdioPayCover/>
    </div>
  )
}
