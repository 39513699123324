import React, { useEffect } from "react";
import "./LearnMore.scss";
import LearnMoreImage from "../../images/learn-more-image.svg";
import StyledButton from "../styledButton";
import AOS from "aos";

export default function LearnMore() {
  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 400, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
    });
  }, []);
  return (
    <div className="learn-more-cover">
      {/* <div className="learn-more-title" data-aos="fade-in">
        About Dizbuzz
      </div> */}
      <div className="learn-more-image-and-caption-cover">
        <div className="row">
          <div
            className="col-lg-6 col-md-6 col-12 learn-more-image-div"
            data-aos="zoom-in"
          >
            <img src={LearnMoreImage} alt="" style={{ width: "100%" }} />
          </div>
          <div
            className="col-lg-6 col-md-6 col-12 learn-more-content-div"
            data-aos="fade-in"
          >
            <h2 className="learn-more-title" data-aos="fade-in">
              About Dizbuzz
            </h2>
            <p>
              At Dizbuzz we believe in putting the customer first. We understand
              the need and desire for those wanting short term and instalment
              loans from a lender they can trust. As a result, we have developed
              our lending model around this principle.
            </p>
            {/* <p>
              We are all about creating long term sustainable relationships with
              our customers. We want you to feel at ease when dealing with us in
              the comfort of knowing that we are here to help. We analyze
              individual financial habits, identify needs and deliver unique
              affordable products, all these with the speed we represent.
            </p>
            <p>
              Our vision is to redefine service delivery and become the number
              one lending company of choice.
            </p> */}
            <div className="button-title-div">
              <StyledButton
                text={"Learn more"}
                className={"learn-more-mobile-button"}
                background={"#47B638"}
                fontSize={"16px"}
                width={"193px"}
                height={"65px"}
                borderRadius={"5px"}
                border={"none"}
                color={"white"}
                fontFamily={"sen-bold"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
