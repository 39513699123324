import React from "react";
import "./VisionAndMission.scss";

export default function VisionAndMission() {
  return (
    <div className="vision-and-mission-container">
      <div id="mission" className="mission-container">
        <div className="mission-title">Our mission</div>
        <div className="mission-content">
          <p>
            Our mission is to provide our customers with a unique experience and
            be their preferred lending partners.
          </p>
        </div>
      </div>
      <div className="avatar-a-container">
        <div className="avatar-a-wrapper" />
      </div>
      <div id="vision" className="vision-container">
        <div className="mission-title">Our vision</div>
        <div className="mission-content">
          <p>
            Our vision is to redefine service delivery and become the number one
            lending company of choice in Nigeria.
          </p>
        </div>
      </div>
      <div className="avatar-b-container">
        <div className="avatar-b-wrapper" />
      </div>
    </div>
  );
}
