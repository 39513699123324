import React from 'react'
import "./ClientCommentCard.scss"
import "./client.css"

export default function ClientCommentCard({image,title,name}) {

  return (<div className='client-comment-cover client-comment-card'>
  <div className='comment-image-div'>
      <img src={image} alt=""/>
  </div>
  <div className='comment-content-div'>
      {
          title
      }
  </div>
  <div className='comment-name-div'>
      {
          name
      }
  </div>
</div>)

}
